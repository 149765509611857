import React, { createContext, useContext } from "react";
import { Instance, flow, getEnv, types } from "mobx-state-tree";
import {
  NetworkingStore,
  createNetworkingStore,
} from "@pulse/shared-components";
import { SurveyStore, createSurveyStore } from "./SurveyStore";

// Mobx-react recommends injection of stores using React.createContext as compared to using the build in Provider / inject.
// For more info - https://github.com/mobxjs/mobx-react#provider-and-inject

export const RootStore = types
  .model("RootStore", {
    networkingStore: NetworkingStore,
    surveyStore: SurveyStore,
  })
  .views((store) => ({
    get baseURL(): string {
      return getEnv(store).baseURL;
    },
  }))
  .actions((store) => ({
    initialiseSurvey: flow(function* (
      surveyLinkId: string,
      isPreviewURL: boolean,
    ) {
      yield store.surveyStore.getCurrentQuestionAndSettings(
        surveyLinkId,
        isPreviewURL,
      );
    }),
  }));

const RootStoreContext = createContext<null | Instance<typeof RootStore>>(null);

export const RootStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const rootStore = RootStore.create(
    {
      networkingStore: createNetworkingStore(),
      surveyStore: createSurveyStore(),
    },
    {
      baseURL: import.meta.env.VITE_APP_API_CLIENT_BASE_URL,
    },
  );

  return (
    <RootStoreContext.Provider value={rootStore}>
      {children}
    </RootStoreContext.Provider>
  );
};

export function useRootStore(): Instance<typeof RootStore> {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
