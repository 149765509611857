import React, { useCallback, useEffect, useRef, useState } from "react";
import { Router as AppRouter } from "./routes/Routes";
import "@pulse/shared-components/src/i18n/config";
import {
  FoundationTheme,
  LoadingIndicator,
  ThemeConfig,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  EMPTY_CHARACTER,
  darkTheme,
  lightTheme,
} from "@pulse/shared-components";
import { useRootStore } from "./modules/root/store/RootStore";
import {
  getBrowserColorTheme,
  getDynamicTheme,
} from "@pulse/shared-components";
import {
  processSurveyLiveParams,
  useRouteNavigator,
} from "./routes/RoutesHelper";
import { Stack } from "@mui/material";
import "@pulse/shared-components/src/App.css";
import { navigateOrGetNetworkErrorPage } from "./utils/navigateOrGetNetworkErrorPage";

export function App(): JSX.Element {
  const store = useRootStore();
  const navigateTo = useRouteNavigator();
  const [themes, setThemes] = useState<FoundationTheme[]>([
    lightTheme(),
    darkTheme(),
  ]);
  const [isThemeInitialised, setIsThemeInitialised] = useState(false);

  const fetchThemes = async (
    surveyLinkId: string,
    isPreviewURL: boolean,
  ): Promise<void> => {
    await store.initialiseSurvey(surveyLinkId, isPreviewURL);
    const lightTokens = store.surveyStore.lightThemeTokens;
    const darkTokens = store.surveyStore.darkThemeTokens;
    if (lightTokens && darkTokens && store.surveyStore.surveySettings) {
      setThemes(
        getDynamicTheme(
          [
            lightTokens.primary100Code,
            lightTokens.primary200Code,
            lightTokens.primary300Code,
            lightTokens.primary400Code,
            lightTokens.primary500Code,
          ],
          [
            darkTokens.primary100Code,
            darkTokens.primary200Code,
            darkTokens.primary300Code,
            darkTokens.primary400Code,
            darkTokens.primary500Code,
          ],
          store.surveyStore.surveySettings.font,
        ),
      );
    } else {
      navigateOrGetNetworkErrorPage(
        store.networkingStore.errorStore.error,
        navigateTo,
      );
      console.error(
        "LightTokens, DarkTokens and surveySettings must not be null in this flow.",
      );
      if (store.networkingStore.errorStore.error === null) {
        navigateTo.internalServerError();
      }
    }
  };
  const getThemes = useCallback(async () => {
    await processSurveyLiveParams(
      async (surveyLinkId, isPreviewURL) => {
        await fetchThemes(surveyLinkId, isPreviewURL);
      },
      navigateTo,
      store.surveyStore.generateGenericSurveyLink,
    );
    setIsThemeInitialised(true);
  }, []);

  // Below regex check is added to run the useEffect only once in strict mode when we are running the app locally.
  // fetchThemes fetches the current settings as well. So, if we don't show the welcome page or take content of user,
  // then we are updating the survey link status to started.
  // In strict mode, the use effect will run twice and make that API call twice as well, which will throw 500 because of concurrent updates in DB.
  // The else block will run in prod.
  const localhostRegex = /localhost/;
  if (localhostRegex.test(store.baseURL ?? EMPTY_CHARACTER)) {
    const isThemeFetched = useRef(false);
    useEffect(() => {
      if (!isThemeFetched.current) {
        getThemes();
      }
      return () => {
        isThemeFetched.current = true;
      };
    }, []);
  } else {
    useEffect(() => {
      getThemes();
    }, []);
  }

  return (
    <Stack className={getBrowserColorTheme()}>
      <ThemeConfig themes={themes} currentTheme={getBrowserColorTheme()}>
        {isThemeInitialised ? (
          <AppRouter />
        ) : (
          <LoadingIndicator isLoading={true} />
        )}
      </ThemeConfig>
    </Stack>
  );
}
